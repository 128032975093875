<template>
  <v-row no-gutters class="pa-3 justify-space-between">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          small
          dark
          v-bind="attrs"
          v-on="on"
          class="text-none"
        >
          Sort by: {{ selectedSortOption }}
        </v-btn>
      </template>
      <v-list class="px-3 mt-2">
        <v-list-item-title
          v-for="option in options"
          :key="option.id"
          @click="$emit('sort-by', mediaView, option.id)"
          class="sort-option"
          :class="{ active: option.id === selectedSortOption }"
        >
          {{ option.title }}
        </v-list-item-title>
      </v-list>
    </v-menu>

    <div class="list-types">
      <v-btn
        @click="$emit('change-list-type', 'icons')"
        :class="selectedListType === 'icons' ? 'active' : ''"
        icon
        small
        class="mr-3"
      >
        <v-icon small class="pa-2">mdi-apps </v-icon>
      </v-btn>
      <v-btn
        @click="$emit('change-list-type', 'list')"
        :class="selectedListType === 'list' ? 'active' : ''"
        icon
        small
      >
        <v-icon small>mdi-format-list-bulleted-square </v-icon>
      </v-btn>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "ListActionBtns",
  props: ["selectedSort", "selectedListType", "mediaView"],
  data: () => ({
    options: [
      { id: "az", title: "A - Z" },
      { id: "rating", title: "Rating" },
      { id: "newest", title: "Newest added" },
    ],
  }),
  computed: {
    selectedSortOption() {
      const found = this.options.find((o) => this.selectedSort === o.id);
      return found.title;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #005f73;
.sort-option {
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  margin-bottom: 10px;
  cursor: pointer;

  &:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &.active {
    color: $primary;
    font-weight: bold;
  }
}

.list-types {
  .active {
    background-color: $primary;
    color: white;
  }
}
</style>
