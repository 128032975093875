<template>
  <v-card class="result-container" @click="$emit('open-media', mediaData)">
    <img
      v-if="posterImage"
      class="poster-img"
      :src="posterImage"
      alt="poster"
    />
    <div v-else class="poster-img no-poster">
      <v-icon>mdi-movie</v-icon>
    </div>
    <div>
      <h3>{{ mediaData.title || mediaData.name }}</h3>
      <h4 v-if="mediaData.totalRating" class="total-rating">
        <v-icon color="#FFB300">mdi-star</v-icon> {{ mediaData.totalRating }} /
        10
      </h4>
    </div>
  </v-card>
</template>

<script>
/* eslint-disable */
export default {
  name: "MediaButton",
  props: ["mediaData"],
  computed: {
    posterImage() {
      let img = this.mediaData.poster;
      if (this.mediaData.poster.charAt(0) === '/') {
        img = `https://image.tmdb.org/t/p/original${this.mediaData.poster}`
      }
      return img;
    }
  }
};
</script>

<style lang="scss" scoped>
.result-container {
  width: 21%;
  margin: 0 2% 20px;
  text-align: center;
  .poster-img {
    height: 200px;
    margin: 0 auto;
    margin-top: 10px;
    &.no-poster {
      background: #cacaca;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      width: 80%;
    }
  }

  &.list-view {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .poster-img {
      height: 80px;
      margin: 10px;
    }
    > div {
      width: 80%;
    }
  }
}

@media screen and (max-width: 650px) {
  .result-container {
    width: 46%;
  }
}
.total-rating {
  padding-bottom: 5px;
  i {
    margin-left: -5px;
  }
}
</style>
