import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDzZGUCnmKBxa5Luq8KslMYAI_k7fClyCk",
  authDomain: "nacht-approved.firebaseapp.com",
  projectId: "nacht-approved",
  storageBucket: "nacht-approved.appspot.com",
  messagingSenderId: "483333737316",
  appId: "1:483333737316:web:4a5b5bebae8f6b8a454871",
};

initializeApp(firebaseConfig);

const db = getFirestore();
export default db;
