<template>
  <v-container v-if="searchMode" class="px-3">
    {{ selected }}
    <v-row class="justify-center" no-gutters>
      <v-text-field
        v-model="search"
        outlined
        class="mt-4"
        @keydown="searchMedia()"
        append-icon="mdi-magnify"
        placeholder="search here"
        background-color="white"
      ></v-text-field>
    </v-row>

    <v-row>
      <media-button
        v-for="result in results"
        :key="result.id"
        @open-media="openMedia(result)"
        :mediaData="result"
      ></media-button>
    </v-row>
  </v-container>

  <v-container v-else>
    <v-card class="add-form mx-2 pa-3 mt-2">
      <h2>{{ selected.title || selected.name }}</h2>
      <v-divider class="mt-2 mb-4"></v-divider>
      <AddForm @submit-review="addMedia" :mediaType="this.selectedMedia" />
    </v-card>
  </v-container>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import db from "../firebase/init";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import MediaButton from "./mediaButton";
import AddForm from "./AddForm.vue";

export default {
  name: "AddView",
  props: ["selectedMedia", "movies", "tvShows"],
  components: { MediaButton, AddForm },
  data: () => ({
    search: "",
    results: null,
    loading: false,
    searchMode: true,
    selected: null,
  }),
  methods: {
    async addMedia(reviewData) {
      const toAdd = {
        id: this.selected.id,
        title: this.selected.title || this.selected.name,
        mediaType: this.selectedMedia,
        poster: this.selected["poster_path"] || this.selected.image?.medium,
        totalRating: reviewData.rating,
        created: serverTimestamp(),
        reviews: [
          {
            rating: reviewData.rating,
            platform: reviewData.platform,
            comments: reviewData.comments,
            user: reviewData.user,
            seasonCount: reviewData.seasonCount,
          },
        ],
      };
      await setDoc(doc(db, "reviews", `${this.selected.id}`), toAdd).then(
        () => {
          this.$emit("change-view", "list");
        }
      );
    },
    searchMedia() {
      if (this.search?.length < 2) return;
      if (this.selectedMedia === 'tv') { 
        axios
          .get(
            `https://api.tvmaze.com/search/shows?q=${this.search}&page=1`
          )
          .then((data) => {
            const results = data.data.map((r) => {
              return {
                ...r.show,
                poster: r.show.image?.medium,
              };
            });
            this.results = results;
          });
      } else {
        axios
          .get(
            `https://api.themoviedb.org/3/search/${this.selectedMedia}?api_key=6fd034526f06137a099aea2cbb29af58&page=1&query=${this.search}`
          )
          .then((data) => {
            const results = data.data.results.map((r) => {
              return {
                ...r,
                poster: r["poster_path"],
              };
            });
            this.results = results;
          });
      }
    },
    openMedia(selected) {
      const foundInShows = !!this.tvShows.find(tv => tv.id === selected.id);
      const foundInMovies = !!this.movies.find(movie => movie.id === selected.id);
      if (foundInShows || foundInMovies) {
        this.$emit('change-view','review' ,{id: selected.id, mediaType: this.selectedMedia})
      } else {
        this.openAddForm(selected);
      }
    },
    openAddForm(selectedMedia) {
      this.searchMode = false;
      this.selected = selectedMedia;
    },
    returnToSearch() {
      this.searchMode = true;
      this.selected = null;
      this.resetForm();
    },
    resetForm() {
      this.rating = null;
      this.platform = null;
      this.comments = null;
      this.user = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.result-container {
  width: 21%;
  margin: 0 2% 20px;
  text-align: center;
  .poster-img {
    height: 200px;
    margin: 0 auto;
    margin-top: 5px;
    &.no-poster {
      background: #cacaca;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      width: 80%;
    }
  }
}

@media screen and (max-width: 650px) {
  .result-container {
    width: 46%;
  }
}

.add-form {
  h3 {
    margin-bottom: 5px;
    .v-icon {
      margin-top: -3px;
      margin-right: 5px;
    }
  }
}
</style>
