<template>
  <v-container>
    <v-row no-gutters class="main-container">
      <div class="col-container">
        <div class="selectedReview-container">
          <img
            v-if="posterImage"
            class="poster-img"
            :src="posterImage"
            alt="poster"
          />
          <div v-else class="poster-img no-poster">
            <v-icon>mdi-movie</v-icon>
          </div>
          <h3>{{ selectedReview.title || selectedReview.name }}</h3>
          <h4>
            <v-icon color="#FFB300">mdi-star</v-icon>
            {{ selectedReview.totalRating }} / 10
          </h4>
        </div>
      </div>

      <div class="col-container">
        <!-- ADD REVIEW -->
        <v-btn
          v-if="!addMode"
          @click="addMode = true"
          depressedd
          color="primary"
          width="100%"
          class="my-3"
        >
          <v-icon>mdi-plus</v-icon> Add a review
        </v-btn>

        <AddForm
          v-else
          @submit-review="addReview"
          :cancelBtn="true"
          :mediaType="selectedReview.mediaType"
          @cancel-add="addMode = false"
        />

        <!-- REVIEWS -->
        <div v-if="!addMode">
          <v-card
            v-for="(review, i) in selectedReview.reviews"
            :key="i"
            class="review-container"
          >
            <h4>
              <v-icon color="#FFB300">mdi-star</v-icon> {{ review.rating }} / 10
            </h4>
            <v-row no-gutters class="review-details">
              <span>
                <v-icon>mdi-account-circle</v-icon> {{ review.user }}
              </span>
              <span v-if="review.platform" class="hasBorder">
                <v-icon>mdi-television</v-icon>
                {{ review.platform }}
              </span>
              <span v-if="review.seasonCount" class="hasBorder">
                {{ review.seasonCount }} Season(s)
              </span>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <p>{{ review.comments }}</p>
          </v-card>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import AddForm from "./AddForm.vue";
import db from "../firebase/init";
import { setDoc, doc } from "firebase/firestore";
export default {
  name: "ReviewView",
  props: ["selectedReview"],
  components: { AddForm },
  data: () => ({
    addMode: false,
  }),
  methods: {
    async addReview(reviewData) {
      const updated = { ...this.selectedReview };
      updated.reviews.push(reviewData);
      await setDoc(
        doc(db, "reviews", `${this.selectedReview.id}`),
        updated
      ).then(() => {
        this.addMode = false;
      });
    },
  },
  computed: {
    posterImage() {
      let img = this.selectedReview.poster;
      if (this.selectedReview.poster.charAt(0) === '/') {
        img = `https://image.tmdb.org/t/p/original${this.selectedReview.poster}`
      }
      return img;
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #005f73;
.main-container {
  background-color: #f0f0f0;
  border: $primary solid 2px;
  border-radius: 10px;
  padding: 5px 10px 5px 5px;
  justify-content: space-between;
}

.selectedReview-container {
  text-align: center;
  padding: 10px;
  .poster-img {
    width: 100%;
    margin: 0 auto;
    margin-top: 5px;
    &.no-poster {
      background: #cacaca;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      width: 80%;
    }
  }
}

.review-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.review-details {
  margin-top: 5px;
  span.hasBorder {
    margin-left: 10px;
    padding-left: 10px;
    border-left: grey 1px solid;
  }
  i {
    font-size: 20px;
  }
}

.col-container {
  &:first-child {
    width: 30%;
  }
  &:last-child {
    width: 68%;
  }
}
@media screen and (max-width: 470px) {
  .col-container {
    &:first-child {
      width: 100%;
      margin-bottom: 15px;
    }
    &:last-child {
      width: 100%;
    }
  }
  .selectedReview-container {
    .poster-img {
      width: auto;
      max-height: 200px;
    }
  }
}
</style>
