<template>
  <v-form ref="addForm" class="pb-3">
    <h3><v-icon>mdi-account</v-icon> Your name</h3>
    <v-select
      v-model="user"
      :items="users"
      outlined
      placeholder="Select user"
      background-color="white"
      :rules="[rules.required]"
    ></v-select>

    <h3><v-icon>mdi-star</v-icon> Rating</h3>
    <v-text-field
      v-model="rating"
      outlined
      placeholder="1 - 10"
      :rules="[rules.rating]"
      background-color="white"
    ></v-text-field>

    <h3><v-icon>mdi-television-play</v-icon> Streaming platform</h3>
    <v-text-field
      v-model="platform"
      outlined
      placeholder="Netflix, HBO, etc."
      background-color="white"
    ></v-text-field>

    <h3 v-if="mediaType === 'tv'">
      <v-icon>mdi-television-play</v-icon> Seasons
    </h3>
    <v-text-field
      v-if="mediaType === 'tv'"
      v-model="seasonCount"
      outlined
      placeholder="Amount of seasons available"
      background-color="white"
    ></v-text-field>

    <h3><v-icon>mdi-message-text</v-icon> Comments</h3>
    <v-textarea
      v-model="comments"
      outlined
      background-color="white"
    ></v-textarea>

    <v-btn
      v-if="cancelBtn"
      @click="$emit('cancel-add')"
      depressed
      width="48%"
      class="mr-3"
      color="transparent"
    >
      Cancel
    </v-btn>
    <v-btn
      @click="submitReview()"
      color="primary"
      :width="cancelBtn ? '48%' : '100%'"
    >
      Submit
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: "AddForm",
  props: ["cancelBtn", "mediaType"],
  data: () => ({
    rating: null,
    platform: null,
    comments: null,
    user: null,
    seasonCount: 0,
    ratingError: false,
    users: [
      "Lily",
      "Abigail",
      "Dylan",
      "Sarahjane",
      "Leigh",
      "Isabel",
      "Gordy",
      "Henning",
    ],
    rules: {
      rating: (value) => {
        return !!parseFloat(value) || "Needs to be an integer or decimal";
      },
      required: (value) => {
        return !!value || "Required";
      },
    },
  }),
  methods: {
    submitReview() {
      if (!this.$refs.addForm.validate()) return;
      this.$emit("submit-review", {
        rating: parseFloat(this.rating),
        platform: this.platform,
        comments: this.comments,
        user: this.user,
        seasonCount: this.seasonCount,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 7px;
  i {
    margin-right: 3px;
  }
}
</style>
