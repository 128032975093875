<template>
  <v-app>
    <v-main class="main-container">
      <v-app-bar flat color="#bb3e03">
        <v-col class="pa-0" :cols="8">
          <h2 class="white--text py-2">
            <v-icon class="logo">mdi-television-classic</v-icon> Nacht Approved
          </h2>
        </v-col>
        <v-col class="pa-0 text-right" :cols="4">
          <v-btn
            v-if="view === 'list'"
            @click="view = 'add'"
            depressed
            color="#bb3e03"
            dark
            small
          >
            <v-icon small>mdi-plus</v-icon> Add {{ selectedMediaTitle }}
          </v-btn>
          <v-btn
            v-else
            @click="changeView('list')"
            depressed
            color="#bb3e03"
            dark
            small
          >
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            return to list
          </v-btn>
        </v-col>
      </v-app-bar>
      <div class="borders">
        <div class="border"></div>
        <div class="border"></div>
        <div class="border"></div>
      </div>
      <List
        v-if="view === 'list' && tvShows.length && movies.length"
        :movies="movies"
        :tvShows="tvShows"
        @open-media-review="openMediaReview"
        @change-media-selection="changeMediaSelection"
        @change-view="changeView"
      />
      <Add
        v-else-if="view === 'add'"
        :selectedMedia="selectedMedia"
        :movies="movies"
        :tvShows="tvShows"
        @change-view="changeView"
      />
      <Review v-else-if="view === 'review'" :selectedReview="selectedReview" />
    </v-main>
  </v-app>
</template>

<script>
/* eslint-disable */
import db from "./firebase/init";
import { collection, query, where, getDocs } from "firebase/firestore";
import Add from "./components/Add";
import List from "./components/List";
import Review from "./components/Review";

export default {
  name: "App",
  components: {
    Add,
    List,
    Review,
  },
  data: () => ({
    view: "list",
    selectedMedia: "tv",
    movies: [],
    tvShows: [],
    selectedMediaTitle: "TV Show",
    selectedReview: null,
  }),
  mounted() {
    this.getListItems();
  },
  methods: {
    async getListItems() {
      this.movies = [];
      this.tvShows = [];
      const reviewsRef = collection(db, "reviews");
      const movieQuery = query(reviewsRef, where("mediaType", "==", "movie"));
      const movieSnapshot = await getDocs(movieQuery);
      const tvQuery = query(reviewsRef, where("mediaType", "==", "tv"));
      const tvSnapshot = await getDocs(tvQuery);
      if (tvSnapshot) {
        tvSnapshot.forEach((doc) => {
          this.tvShows.push(doc.data());
        });
      }
      if (movieSnapshot) {
        movieSnapshot.forEach((doc) => {
          this.movies.push(doc.data());
        });
      }
    },
    changeView(view, reviewToShow) {
      if (reviewToShow?.id) {
        let found = null;
        if (reviewToShow.mediaType === "tv") {
          found = this.tvShows.find((tv) => tv.id === reviewToShow.id);
        } else {
          found = this.movies.find((movie) => movie.id === reviewToShow.id);
        }
        this.selectedReview = found;
      } else {
        this.selectedReview = null;
        this.getListItems();
      }
      this.view = view;
    },
    openMediaReview(selected) {
      this.selectedReview = selected;
      this.view = "review";
    },
    changeMediaSelection(selectedView) {
      if (selectedView < 1) {
        this.selectedMediaTitle = "TV Show";
        this.selectedMedia = "tv";
      } else {
        this.selectedMediaTitle = "Movie";
        this.selectedMedia = "movie";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-family: "Roboto Mono", monospace;
}
.main-container {
  background-color: #e9d8a6;
}

.border {
  width: 100%;
  height: 10px;
  &:first-child {
    background-color: #ca6702;
  }
  &:nth-child(2) {
    background-color: #ee9b00;
  }
  &:last-child {
    background-color: #e9d8a6;
  }
}

.logo {
  color: #fff;
  font-size: 36px;
  padding-bottom: 7px;
}

@media only screen and (max-width: 425px) {
  h2 {
    font-size: 18px;
  }
  .logo {
    font-size: 25px;
  }
  .v-btn.v-size--small {
    font-size: 0.65rem;
    min-width: auto;
    padding: 0;
  }
  ::v-deep .v-toolbar__content {
    padding: 0 7px;
  }
}
</style>
