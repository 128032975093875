<template>
  <v-container>
    <v-tabs
      v-model="mediaView"
      @change="$emit('change-media-selection', mediaView)"
      background-color="transparent"
      grow
    >
      <v-tab>TV Shows</v-tab>
      <v-tab :class="{ 'on-movies': mediaView }">Movies </v-tab>
    </v-tabs>
    <v-tabs-items v-model="mediaView" :class="{ 'on-movies': mediaView }">
      <!-- TV -->
      <v-tab-item>
        <v-row v-if="!tvShows.length" no-gutters class="mt-3 justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="70"
            class="my-6"
          ></v-progress-circular>
        </v-row>
        <ListActionBtns
          v-if="tvShows.length"
          :selectedSort="selectedTVSort"
          :selectedListType="selectedListType"
          :mediaView="mediaView"
          @sort-by="sortBy"
          @change-list-type="changeListType"
        />
        <v-row no-gutters class="mt-3">
          <media-button
            v-for="show in sortedTv"
            :key="show.id"
            @open-media="openMediaReview(show)"
            :mediaData="show"
            :class="{ 'list-view': selectedListType === 'list' }"
          ></media-button>
        </v-row>
      </v-tab-item>
      <!-- MOVIES -->
      <v-tab-item>
        <v-row v-if="!movies.length" no-gutters class="mt-3 justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="70"
            class="my-6"
          ></v-progress-circular>
        </v-row>
        <ListActionBtns
          v-if="movies.length"
          :selectedSort="selectedMovieSort"
          :selectedListType="selectedListType"
          :mediaView="mediaView"
          @sort-by="sortBy"
          @change-list-type="changeListType"
        />
        <v-row v-if="movies.length" no-gutters class="mt-3">
          <media-button
            v-for="movie in sortedMovies"
            :key="movie.id"
            @open-media="openMediaReview(movie)"
            :mediaData="movie"
            :class="{ 'list-view': selectedListType === 'list' }"
          ></media-button>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
/* eslint-disable */
import MediaButton from "./mediaButton";
import ListActionBtns from "./ListActionBtns.vue";
export default {
  name: "ListView",
  props: ["movies", "tvShows"],
  components: { MediaButton, ListActionBtns },
  data: () => ({
    mediaView: "TV Shows",
    views: ["tv", "movie"],
    selectedTVSort: "newest",
    selectedMovieSort: "newest",
    selectedListType: "icons",
    sortedTv: [],
    sortedMovies: [],
  }),
  mounted() {
    this.sortTvShows();
    this.sortMovies();
  },
  methods: {
    openMediaReview(selectedMedia) {
      this.$emit("open-media-review", selectedMedia);
    },
    sortTvShows() {
      this.sortedTv = [];
      let sorted = this.tvShows;
      if (this.selectedTVSort === "rating") {
        sorted = sorted.sort((a, b) => b.totalRating - a.totalRating);
      } else if (this.selectedTVSort === "az") {
        sorted = sorted.sort((a, b) => a.title.localeCompare(b.title));
      } else {
        sorted = sorted.sort((a, b) => {
          return new Date(b.created.seconds * 1000) - new Date(a.created.seconds * 1000);
        });
      }
      this.sortedTv = sorted;
    },
    sortMovies() {
      let sorted = [...this.movies];
      if (this.selectedMovieSort === "rating") {
        sorted = sorted.sort((a, b) => b.totalRating - a.totalRating);
      } else if (this.selectedMovieSort === "az") {
        sorted = sorted.sort((a, b) => a.title.localeCompare(b.title));
      } else {
        sorted = sorted.sort((a, b) => {
          return new Date(b.created.seconds * 1000) - new Date(a.created.seconds * 1000);
        });
      }
      this.sortedMovies = sorted;
    },
    sortBy(viewInt, option) {
      if (this.views[viewInt] === "tv") {
        this.selectedTVSort = option;
        this.sortTvShows();
      } else if (this.views[viewInt] === "movie") {
        this.selectedMovieSort = option;
        this.sortMovies();
      }
    },
    changeListType(type) {
      this.selectedListType = type;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #005f73;
.v-tabs-items {
  background-color: #f0f0f0;
  border: $primary solid 2px;
  border-radius: 0px 10px 10px 10px;
  &.on-movies {
    border-radius: 10px 0px 10px 10px;
  }
}
.v-tab {
  background-color: #e9d8a6;
  color: $primary !important;
}
.v-tab--active {
  background-color: $primary;
  color: #fff !important;
  border-radius: 10px 10px 0px 0px;
  &.on-movies {
    border-radius: 10px 10px 0px 0px;
  }
}
</style>
